.container-loader {
    background-color: #dadde3;
    display: flex;
    height: 100Vh;
    justify-content: center;
    align-items: center;
    width: 100%;
    position: absolute;
    flex-direction: column;
    top: 0;
    z-index: 1000000;
    animation: bgAnim 4s linear infinite ;
}

@keyframes bgAnim {
    0%{
        background-color: #d0cde1;

    }
    10%{
        background-color: #d0cde1;

    }
    20%{
        background-color: #708ebb;
    }
    30%{
        background-color: #708ebb;
    }
    40%{
        background-color: #2874ac;
    }
    50%{
        background-color: #1c80cb;
    }
    60%{
        background-color: #1c80cb;

    }
    70%{
        background-color: #708ebb;
    }
    80%{
        background-color: #708ebb;
    }
    90%{
        background-color: #d0cde1;
    }
    100%{
        background-color: #d0cde1;
    }

}

@keyframes per1Anims {
    0%{
        fill: #0092ff;

    }
    40%{
        fill: #5c92ba;
    }
    40%{
        fill: #a1b6c6;
    }
    50%{
        fill: #d0cde1;
    }
    60%{
        fill: #a1b6c6;

    }
    70%{
        fill: #5c92ba;
    }
    100%{
        fill: #0092ff;
    }

}
.container-loader span{
    color: #f47a71;
    font-weight: bold;
    position: absolute;
    text-align: center;
    margin-top: -30vh;
    left: 45vw;
}

.cls-2{clip-path:url(#clip-path);}
.dadanadaN *{
    margin-left: -300px !important;
}
.container-loader .cls-2,.container-loader .cls-1{
    fill:#0092ff;
    transform-origin: center;
    animation: per1Anims 4s linear infinite ;
}
.container-loader .cls-3,.cls-4{fill:#ff9802;}
.container-loader .logoImageSvg{
    height: 70px;
    width: 300px;
}
